import React from 'react';
import ContentBox, { ContentBoxProps } from '@components/views/ContentBox';
import Container from '@components/containers/Container';
import clsx from 'clsx';

export interface ContentBoxesProps {
  content_boxes: Array<{
    box: ContentBoxProps;
  }>;
  isBackground?: boolean;
  theme?: ContentBoxProps['theme'];
  link_in_description: boolean;
  iconBackgroundShape?: ContentBoxProps['iconBackgroundShape'];
  iconSize?: ContentBoxProps['iconSize'];
  textsStyle?: ContentBoxProps['textsStyle'];
}

const Inner: React.FunctionComponent<ContentBoxesProps> = ({
  content_boxes,
  theme,
  link_in_description,
  iconBackgroundShape,
  iconSize,
  textsStyle,
}) => (
  <div
    className={clsx('grid sm:grid-cols-2 gap-32 md:gap-y-64 md:gap-32', {
      'lg:grid-cols-2': content_boxes.length === 2,
      'lg:grid-cols-3': content_boxes.length === 3,
      'lg:grid-cols-4': content_boxes.length > 3,
    })}
  >
    {content_boxes.map((item, index) => {
      const { box } = item;

      return (
        <ContentBox
          {...box}
          heading={box.heading}
          key={index}
          theme={theme}
          link_in_description={link_in_description}
          iconBackgroundShape={iconBackgroundShape}
          iconSize={iconSize}
          textsStyle={textsStyle}
        />
      );
    })}
  </div>
);

const ContentBoxes: React.FunctionComponent<ContentBoxesProps> = (props) => {
  return (
    <section
      className={clsx({
        'mb-64': !props.isBackground,
        'py-48 md:py-96': props.isBackground,
      })}
    >
      <Container>
        <Inner {...props} />
      </Container>
    </section>
  );
};
export default ContentBoxes;
