import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import ChevronIcon from '../../../assets/images/icons/chevron-right.svg';
import clsx from 'clsx';
import { ILinkACF } from 'src/types/ILinkACF';
import Heading, { HeadingProps } from '../Heading';
import { ProductColors, useColors } from 'src/context/colors.context';
import styled, { css } from 'styled-components';

export interface ContentBoxProps {
  icon: string;
  heading: HeadingProps;
  description: string;
  link?: ILinkACF;
  link_in_description?: boolean;
  theme?: 'light' | 'transparent' | 'washed';
  iconBackgroundShape?: 'SQUARE' | 'RECTANGLE' | 'FLAT';
  iconSize?: { width: number; height: number };
  textsStyle?: 'DEFAULT' | 'PRIMARY';
}

const InnerStyled = styled.div<{
  productColors?: ProductColors;
  variant?: ContentBoxProps['theme'];
}>`
  ${({ productColors, variant, theme }) =>
    variant === 'washed' &&
    css`
      background-color: ${productColors?.washed || theme.colors.neutral[1]};
    `}
`;

const Inner: React.FunctionComponent<ContentBoxProps> = (props) => {
  const {
    icon,
    description,
    link_in_description,
    theme,
    link,
    heading,
    iconBackgroundShape = 'SQUARE',
    iconSize = { width: 96, height: 96 },
    textsStyle = 'DEFAULT',
  } = props;

  const { productColors } = useColors();

  return (
    <div className="overflow-hidden h-full flex flex-col">
      <InnerStyled
        className={clsx('mb-20 flex items-center overflow-hidden', {
          'bg-neutral-1': !theme,
          'bg-transparent': theme === 'transparent',
          'bg-neutral-0': theme === 'light',
          'md:aspect-1 justify-center': iconBackgroundShape === 'SQUARE',
          'aspect-[1.4] justify-center': iconBackgroundShape === 'RECTANGLE',
          'p-36': iconBackgroundShape !== 'FLAT',
        })}
        productColors={productColors}
        variant={theme}
      >
        {icon && (
          <div className="transition-transform transform group-hover:scale-105">
            <Image
              src={icon}
              alt=""
              width={iconSize.width}
              height={iconSize.height}
            />
          </div>
        )}
      </InnerStyled>
      <div
        className={clsx('font-extrabold  mb-8', {
          'text-neutral-9': textsStyle === 'DEFAULT',
          'text-neutral-8': textsStyle === 'PRIMARY',
        })}
      >
        <Heading {...heading}>{heading.title}</Heading>
      </div>
      <p
        className={clsx({
          'mb-16': link_in_description && link,
          'text-neutral-4 text-sm': textsStyle === 'DEFAULT',
          'text-neutral-5': textsStyle === 'PRIMARY',
        })}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {link_in_description && link && (
        <div className="mt-auto">
          <span className="flex items-center hover:underline">
            <div dangerouslySetInnerHTML={{ __html: link.title }} />
            <ChevronIcon fill="fillCurrent" className="ml-12" />
          </span>
        </div>
      )}
    </div>
  );
};

/**
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=552%3A13715
 */
const ContentBox: React.FunctionComponent<ContentBoxProps> = (props) => {
  const { link } = props;

  if (link?.url) {
    return (
      <Link href={link.url} passHref>
        <a
          target={link.target}
          className="transition-opacity hover:opacity-80 group"
        >
          <Inner {...props} />
        </a>
      </Link>
    );
  }

  return <Inner {...props} />;
};

export default ContentBox;
