import clsx from 'clsx';
import pick from 'lodash/pick';
import React, { ButtonHTMLAttributes, ReactNode, ElementType } from 'react';
import isString from 'lodash/isString';
import { useColors } from 'src/context/colors.context';

export interface ChipProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'PRIMARY'
    | 'PRIMARY-LIGHT'
    | 'PRIMARY-DISABLED'
    | 'PRIMARY-GRAY'
    | 'BORDERED-LIGHT'
    | 'BORDERED-DARK'
    | 'BORDERED-GRAY'
    | 'BORDERED-DISABLED'
    | 'BORDERED-LIGHT-WHITE'
    | 'BORDERED-LIGHT-BOLD'
    | 'BORDERED-GRAY-SECONDARY';
  icon?: {
    component?: ReactNode;
    reverse?: boolean;
  };
  size?: 'MEDIUM' | 'LARGE' | 'SMALL' | 'EXTRA-SMALL';
  as?: ElementType;
  fullWidth?: boolean;
  title?: string;
}

const noBiggerLetterSpacingVariants: ChipProps['variant'][] = [
  'PRIMARY',
  'PRIMARY-LIGHT',
  'PRIMARY-GRAY',
  'PRIMARY-DISABLED',
  'BORDERED-GRAY',
];

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=4%3A132
 */
const Chip: React.FunctionComponent<ChipProps> = (props) => {
  const { icon, variant, children, size, as, fullWidth, title } = props;
  const { conferenceColors } = useColors();

  const Tag = `${as ? as : props.onClick ? 'button' : 'div'}` as ElementType;

  const renderContent = () => {
    const getChildren = isString(children) ? (
      <span dangerouslySetInnerHTML={{ __html: children }} />
    ) : (
      <span>{children}</span>
    );

    return (
      <>
        {title ? (
          <span dangerouslySetInnerHTML={{ __html: title }} />
        ) : (
          getChildren
        )}
      </>
    );
  };

  return (
    <Tag
      className={clsx(`inline-flex px-8 py-4 rounded-4`, {
        'text-neutral-0 bg-neutral-9 text-sm font-medium':
          variant === 'PRIMARY',
        'text-neutral-9 bg-neutral-0 text-sm font-medium':
          variant === 'PRIMARY-LIGHT',
        'text-neutral-9 bg-neutral-2 text-sm font-medium':
          variant === 'PRIMARY-GRAY',
        'text-neutral-5 bg-neutral-2 text-sm font-medium':
          variant === 'PRIMARY-DISABLED',
        'text-neutral-9 border bg-neutral-0': variant === 'BORDERED-LIGHT',
        'text-neutral-9 border-2': variant === 'BORDERED-LIGHT-BOLD',
        'text-neutral-0 border border-neutral-0 bg-neutral-9':
          variant === 'BORDERED-DARK',
        'text-sm bg-transparent border border-neutral-3 text-neutral-5':
          variant === 'BORDERED-GRAY',
        'bg-transparent border border-neutral-3 text-neutral-5':
          variant === 'BORDERED-GRAY-SECONDARY',
        'text-xxs bg-transparent border border-neutral-3 text-neutral-6 uppercase tracking-wider':
          variant === 'BORDERED-DISABLED',
        'border-1 border-neutral-3 uppercase text-xsExtra': size === 'MEDIUM',
        'border-2 border-neutral-9 font-medium uppercase text-xsExtra':
          size === 'LARGE',
        'text-sm border-neutral-3 text-neutral-5': size === 'SMALL',
        'w-full': fullWidth,
        'text-neutral-0 font-extrabold border-2 text-xxs':
          variant === 'BORDERED-LIGHT-WHITE',
        'text-xs': size === 'EXTRA-SMALL',
        'tracking-[0.1rem]':
          !noBiggerLetterSpacingVariants.includes(variant) && size !== 'SMALL',
      })}
      {...(conferenceColors && {
        style: { borderColor: conferenceColors.primary },
      })}
      {...pick(props, ['onClick'])}
    >
      <div
        className={clsx('flex items-center justify-center', {
          'flex-row-reverse': icon?.reverse,
          'w-full': fullWidth,
        })}
      >
        {renderContent()}
        {icon?.component && (
          <span
            className={clsx('flex items-center', {
              'fill-neutral-0': variant === 'PRIMARY',
              'mr-8': icon.reverse,
              'ml-8': !icon.reverse,
            })}
          >
            {icon?.component}
          </span>
        )}
      </div>
    </Tag>
  );
};

Chip.defaultProps = {
  variant: 'PRIMARY',
};

export default Chip;
