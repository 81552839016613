import React from 'react';
import Container from '@components/containers/Container';
import Chip, { ChipProps } from '@components/views/Chip';
import Button from '@components/views/Button';
import Heading, { HeadingProps } from '@components/views/Heading';
import { IButtonACF } from 'src/types/IButtonACF';
import ContentBoxes, {
  ContentBoxesProps,
} from '@components/views/ContentBoxes';

interface SupportSectionProps {
  chip?: ChipProps;
  heading?: HeadingProps;
  description?: string;
  button?: IButtonACF;
  boxes?: ContentBoxesProps;
}

const SupportSection: React.FunctionComponent<SupportSectionProps> = (
  props,
) => {
  const { chip, heading, description, button, boxes } = props;

  return (
    <section className="bg-neutral-1 py-40 md:py-64">
      <div className="bg-neutral-0 mx-auto max-w-1340">
        <Container className="py-40 md:py-64">
          {chip && (
            <div className="mb-8 tracking-widest">
              <Chip {...chip}>{chip.title}</Chip>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-32 mb-64">
            <div>
              {heading && (
                <div className="text-neutral-9 font-extrabold text-5xxl max-w-2xl">
                  <Heading {...heading} />
                </div>
              )}
            </div>
            <div>
              {description && (
                <p
                  className="text-neutral-4"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {button && (
                <div className="-ml-32">
                  <Button {...button} href={button.url}>
                    {button.title}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {boxes && boxes.content_boxes.length > 0 && (
            <ContentBoxes {...boxes} />
          )}
        </Container>
      </div>
    </section>
  );
};
export default SupportSection;
